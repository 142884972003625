import React, { useState } from 'react';
import Team_Calicut from './Image/Teams/Calicut.webp';
import Team_Delhi from './Image/Teams/Delhi.webp';
import Team_Ahmedabad from './Image/Teams/Ahmedabad.webp';
import Team_Mumbai from './Image/Teams/Mumbai.webp';
import Team_Bengaluru from './Image/Teams/Bengaluru.webp';
import Team_Kochi from './Image/Teams/Kochi.webp';
import Team_Kolkata from './Image/Teams/Kolkata.webp';
import Team_Chennai from './Image/Teams/Chennai.webp';
import Team_Hyderabad from './Image/Teams/Hyderabad.webp';

const MatchCard = ({ team1, team2, score, firstTeamName, secondTeamName, result, date, set }) => {
  return (
    <div className="text-center py-1 mb-4 w-full shadow-xl">
      <span className="font-semibold">{result} | {date}</span>
      <div className="flex justify-evenly items-center gap-5 py-4">
        <img src={team1} alt={firstTeamName} className="w-20 h-auto" />
        <span className="text-lg font-bold text-[#3b0067]">{score}</span>
        <img src={team2} alt={secondTeamName} className="w-20 h-auto" />
      </div>
      <p className="my-2">
        <span className="px-2 py-1 border rounded-md mb-2">{set}</span>
      </p>
      <h2 className="bg-[#3b0067] text-white py-2 rounded-md">
        {firstTeamName} <span className="font-bold px-1">beat</span> {secondTeamName} {score}
      </h2>
    </div>
  );
};

const Fixture = () => {
  const [visibleMatches, setVisibleMatches] = useState(8); // State to track the number of visible matches

  const matches = [
    { team1: Team_Calicut, team2: Team_Delhi, score: '3 - 1', firstTeamName: 'Calicut Heroes', secondTeamName: 'Delhi Toofans', result: 'Final', date: '21 Mar 2024', set: '15-13 | 15-10 | 13-15 | 15-12' },
    { team1: Team_Delhi, team2: Team_Ahmedabad, score: '3 - 2', firstTeamName: 'Delhi Toofans', secondTeamName: 'Ahmedabad Defenders', result: 'Eliminator', date: '19 Mar 2024', set: '15-9 | 10-15 | 10-15 | 15-12 | 17-15' },
    { team1: Team_Mumbai, team2: Team_Delhi, score: '3 - 1', firstTeamName: 'Mumbai Meteors', secondTeamName: 'Delhi Toofans', result: 'Super 5', date: '17 Mar 2024', set: '11-15 | 15-12 | 12-15 | 15-17' },
    { team1: Team_Ahmedabad, team2: Team_Delhi, score: '3 - 2', firstTeamName: 'Ahmedabad Defenders', secondTeamName: 'Delhi Toofans', result: 'Super 5', date: '15 Mar 2024', set: '10-15 | 15-11 | 15-10 | 12-15 | 16-18' },
    { team1: Team_Delhi, team2: Team_Calicut, score: '3 - 1', firstTeamName: 'Delhi Toofans', secondTeamName: 'Calicut Heroes', result: 'Super 5', date: '13 Mar 2024', set: '14-16 | 15-9 | 15-11 | 15-13' },
    { team1: Team_Delhi, team2: Team_Bengaluru, score: '3 - 0', firstTeamName: 'Delhi Toofans', secondTeamName: 'Bengaluru Torpedoes', result: 'Super 5', date: '11 Mar 2024', set: '15-13 | 18-16 | 17-15' },
    { team1: Team_Delhi, team2: Team_Ahmedabad, score: '3 - 0', firstTeamName: 'Delhi Toofans', secondTeamName: 'Ahmedabad Defenders', result: 'League', date: '10 Mar 2024', set: '15-11 | 16-14 | 15-12' },
    { team1: Team_Delhi, team2: Team_Kochi, score: '3 - 2', firstTeamName: 'Delhi Toofans', secondTeamName: 'Kochi Blue Spikers', result: 'League', date: '05 Mar 2024', set: '9-15 | 15-17 | 15-10 | 15-8 | 15-8' },
    { team1: Team_Delhi, team2: Team_Kolkata, score: '3 - 0', firstTeamName: 'Delhi Toofans', secondTeamName: 'Kolkata ThunderBolts', result: 'League', date: '03 Mar 2024', set: '15-9 | 16-14 | 17-15' },
    { team1: Team_Delhi, team2: Team_Chennai, score: '3 - 1', firstTeamName: 'Delhi Toofans', secondTeamName: 'Chennai Blitz', result: 'League', date: '01 Mar 2024', set: '15-9 | 15-13 | 12-15 | 19-17' },
    { team1: Team_Delhi, team2: Team_Hyderabad, score: '3 - 1', firstTeamName: 'Delhi Toofans', secondTeamName: 'Hyderabad Black Hawks', result: 'League', date: '26 Feb 2024', set: '15-11 | 13-15 | 15-9 | 15-11' },
    { team1: Team_Calicut, team2: Team_Delhi, score: '3 - 0', firstTeamName: 'Calicut Heroes', secondTeamName: 'Delhi Toofans', result: 'League', date: '23 Feb 2024', set: '15-8 | 15-13 | 16-14' },
    { team1: Team_Delhi, team2: Team_Bengaluru, score: '3 - 0', firstTeamName: 'Delhi Toofans', secondTeamName: 'Bengaluru Torpedoes', result: 'League', date: '18 Feb 2024', set: '15-10 | 15-13 | 21-20' },
    { team1: Team_Mumbai, team2: Team_Delhi, score: '3 - 2', firstTeamName: 'Mumbai Meteors', secondTeamName: 'Delhi Toofans', result: 'League', date: '16 Feb 2024', set: '13-15 | 15-17 | 15-13 | 15-13 | 15-17' },
  ];

  const showMoreMatches = () => {
    setVisibleMatches(prev => prev + 8); // Show 8 more matches when the button is clicked
  };

  return (
    <div className="max-auto md:px-[9rem]">
      <div className="grid md:grid-cols-2 items-center justify-center gap-6 p-1 pt-10 md:px-10">
        {matches.slice(0, visibleMatches).map((match, index) => (
          <MatchCard key={index} {...match} />
        ))}
      </div>
      {visibleMatches < matches.length && (
        <div className="text-center py-4">
          <button onClick={showMoreMatches} className="px-4 py-2 bg-[#ffbd00] text-black rounded-md">
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

export default Fixture;
