import React, {useEffect} from 'react';

import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import { Suspense} from 'react';
import Home from './Components/Home';
import AboutUs from './Components/AboutUs/AboutUs';
import Footer from './Components/Footer';
import SectionContact from './Components/Contact/SectionContact';
import SectionFixtureResult from './Components/FixtureResult/SectionFixtureResult';
import SectionSponsors from './Components/Sponsors/SectionSponsors';
import SectionNewsUpdates from './Components/NewUpdate/SectionNewsUpdates';
import SectionToofaniSquad from './Components/Squad/SectionToofaniSquad';
import SectionNewsLetter from './Components/Newsletter/SectionNewsLetter';
import MainSection from './Components/Gallery/MainSection';
import NewsUpdated from './Components/NewUpdate/NewsUpdated';

function NewUpdateRedirect() {
  useEffect(() => {
      window.open('https://www.google.com/search?q=news+and+updates+on+indian+volleyball', '_blank', 'noopener,noreferrer');
 }, []);
 return;
}

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          {/* <Route index element={<Home />} /> */}
          <Route path='/' element={
            <Suspense fallback={<div>Components are loading please wait....</div>}
            >
              <Home/>
            </Suspense>
          }
          />
          <Route path="/About" Component={AboutUs} />
          {/* <Route path='/News-Updates' Component={SectionNewsUpdates}/> */}
          <Route path='/News-Updates' Component={NewsUpdated}/>
          <Route path='/Squad' Component={SectionToofaniSquad}/>
          <Route path="/Fixtures&Results" Component={SectionFixtureResult} />
          <Route path='/Gallery' Component={MainSection}/>
          <Route path='/Newsletter' Component={SectionNewsLetter}/>
          <Route path='/Sponsors' Component={SectionSponsors} />
          <Route path="/Contact" Component={SectionContact} />
          <Route path="/Contact" Component={SectionContact} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
