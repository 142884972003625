import React from 'react'


import teamA from './Image/Teams/Calicut.webp';
import teamB from './Image/Teams/Delhi.webp';
import teamC from './Image/Teams/Ahmedabad.webp';
import teamD from './Image/Teams/Bengaluru.webp';
import teamE from './Image/Teams/Mumbai.webp'
import teamF from './Image/Teams/Chennai.webp';
import teamG from './Image/Teams/Kolkata.webp';
import teamH from './Image/Teams/Kochi.webp';
import teamI from './Image/Teams/Hyderabad.webp'


const StandingTable = () => {
  const teams = [
    { name: 'Team A', logo: teamA, p: 8, w: 6, l: 2, sd: '21:08', pts: 6 },
    { name: 'Team B', logo: teamB, p: 8, w: 6, l: 2, sd: '20:10', pts: 5 },
    { name: 'Team C', logo: teamC, p: 8, w: 5, l: 3, sd: '16:12', pts: 4 },
    { name: 'Team D', logo: teamD, p: 8, w: 5, l: 3, sd: '17:14', pts: 5 },
    { name: 'Team E', logo: teamE, p: 8, w: 5, l: 3, sd: '19:17', pts: 5 },
    { name: 'Team F', logo: teamF, p: 8, w: 4, l: 4, sd: '15:14', pts: 5 },
    { name: 'Team G', logo: teamG, p: 8, w: 3, l: 5, sd: '12:17', pts: 4 },
    { name: 'Team H', logo: teamH, p: 8, w: 1, l: 7, sd: '11:21', pts: 5 },
    { name: 'Team I', logo: teamI, p: 8, w: 1, l: 7, sd: '05:23', pts: 5 },
    // Add more teams here...
  ];

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full shadow-md rounded-lg ">
        <thead className="bg-[#ffbd00] text-white text-center">
          <tr>
            <th className="py-2 md:px-4 px-1 border-b border-gray-200 text-center md:text-2xl text-xl">Team</th>
            <th className="py-2 md:px-4 px-1 border-b border-gray-200 text-center md:text-2xl text-xl">P</th>
            <th className="py-2 md:px-4 px-1 border-b border-gray-200 text-center md:text-2xl text-xl">W</th> 
            <th className="py-2 md:px-4 px-1 border-b border-gray-200 text-center md:text-2xl text-xl">L</th>
            <th className="py-2 md:px-4 px-1 border-b border-gray-200 text-center md:text-2xl text-xl">SD</th>
            <th className="py-2 md:px-4 px-1 border-b border-gray-200 text-center md:text-2xl text-xl">PTS</th>
          </tr>
        </thead>
        <tbody>
          {teams.map((team, index) => (
            <tr key={index} className="even:bg-gray-50 ">
              <td className="md:py-2 py-1 md:px-4 px-1  text-gray-800 flex items-center justify-center ">
                <img src={team.logo} className='md:mr-2 w-20 h-auto' alt={`${team.name} logo`} />
              </td>
              <td className="md:py-2 py-1 md:px-4 px-1 font-bold text-xl text-center">{team.p}</td>
              <td className="md:py-2 py-1 md:px-4 px-1 font-bold text-xl text-center">{team.w}</td>
              <td className="md:py-2 py-1 md:px-4 px-1 font-bold text-xl text-center">{team.l}</td>
              <td className="md:py-2 py-1 md:px-4 px-1 font-bold text-xl text-center">{team.sd}</td>
              <td className="md:py-2 py-1 md:px-4 px-1 font-bold text-xl text-center">{team.pts}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const ResultTable = () => {
  return (
    <div className='mx-auto m-2 md:px-[13.25rem] mt-14'>
      <div className="w-full bg-white shadow-md rounded-lg py-4">
      <h1 className="text-center text-3xl font-bold my-2 mb-6 text-[#3b0067]">Standings </h1>
        <StandingTable />
      </div>
      {/* <div className='flex justify-center py-2 mt-5'>
        <Link>
         <button className='text-center flex justify-items-center bg-[#ffbd00] px-6 py-1 rounded-md font-semibold'>View all</button>
      </Link>
      </div> */}
    </div>
    
  )
}

export default ResultTable