import React, { useState } from 'react';
import image241 from './Gallery/Image/Gallery1.jpg';
import image242 from './Gallery/Image/Gallery2.jpg';
import image243 from './Gallery/Image/Gallery3.jpg';
import image244 from './Gallery/Image/Gallery4.jpg';
import image245 from './Gallery/Image/Gallery5.jpg';
import image246 from './Gallery/Image/Gallery6.jpg';
import { Link } from 'react-router-dom';


const Gallery = () => {
  const [activeTab, setActiveTab] = useState('2024');

  const images = {
    '2024': [image241, image242, image243, image244, image245, image246],
    '2025':[],
  };

  return (
    <section className='text-center justify-center items-center mx-auto m-2 md:my-10 md:px-[9rem]'>
      <div className="md:my-10 my-2">
        <h2 className='text-center text-3xl font-bold text-[#3b0067] pb-4'>Gallery</h2>
        <div className="flex justify-center mb-5">
            {Object.keys(images).map((year) => (
            <button
                key={year}
                className={`px-7 py-1 m-2 rounded-md text-sm ${
                activeTab === year
                    ? 'bg-[#ffbd00] text-black font-semibold'
                    : 'bg-[#3b0067] text-white font-semibold'
                } rounded`}
                onClick={() => setActiveTab(year)}
            >
                {year}
            </button>
            ))}
        </div>
        <div className="grid md:grid-cols-3 grid-cols-2 gap-6 md:mx-24 my-1  ">
            {images[activeTab].map((src, index) => (
            <div className=' rounded-lg bg-[#3b0067] p-2'>
            <img
                key={index}
                src={src}
                alt={`Gallery ${activeTab} - ${index + 1}`}
                className="w-full h-auto rounded-lg"
            />
            </div>
            ))}
        </div>
      </div>
      <Link to={'/Gallery'}>
        <button className='bg-[#ffbd00] px-8 py-1.5 text-sm rounded-md text-black font-medium'>View All</button>
      </Link>
      
    </section>
  );
};

export default Gallery;
