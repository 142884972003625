import React from 'react'

const SectionNewsLetter = () => {
  return (
    <section className='justify-center text-center py-4'>
        <div className='text-3xl'>Section NewsLetter</div>
    </section>
    
  )
}

export default SectionNewsLetter