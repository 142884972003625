import React from 'react'
import GalleryBanner from './GalleryBanner'
import SectionGallery from './SectionGallery'

const MainSection = () => {
  return (
    <>
    <GalleryBanner/>
    <SectionGallery/>
    </>
  )
}

export default MainSection