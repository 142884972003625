import React, { useState } from 'react';

// Importing images for players and their hover states
// Attacker
import Amal from './Team/With BG/Amal Thomas [Attacker].png';
import Anu from './Team/With BG/Anu James [Attacker].png';
import Lazar from './Team/With BG/Lazar Dodic [Attacker].png';
import Rohit from './Team/With BG/Rohit kumar [Attacker].png';
import Santosh from './Team/With BG/Santosh S [Attacker].png';
// Hover Images
import AmalHover from './Team/Info/Amal Thomas [Attacker].png';
import AnuHover from './Team/Info/Anu James [Attacker].png';
import SantoshHover from './Team/Info/Santhosh S [Attacker].png';
import RohitHover from './Team/Info/Rohit Kumar [Attacker].png';
import DanielHover from './Team/Info/Daniel Aponza [Blocker].png';
import AlanHover from './Team/Info/Alan Sanjan [Blocker].png';
import AyushHover from './Team/Info/Ayush Kasnia [Blocker].png';
import FayisHover from './Team/Info/Fayis Nk [Blocker].png';
import IsamanHover from './Team/Info/Insaman A [Libero].png';
import AnandHover from './Team/Info/Anand K [Libero].png';
import JanshadHover from './Team/Info/Janshad U [Setter].png';
import SaqlainHover from './Team/Info/Saqlain Tariq [Setter].png';
import LazarHover from './Team/Info/Lazar Dodic [Attacker].png';
import ManojHover from './Team/Info/Manoj Kumar [Universal].png';

// Blocker
import Daniel from './Team/With BG/Danial Aponza [Blocker].png';
import Alan from './Team/With BG/Alan Sajan [Blocker].png';
import Anand from './Team/With BG/Anand K [Blocker].png';
import Ayush from './Team/With BG/Ayush Kasnia [Blocker].png';
import Fayis from './Team/With BG/Fayis Nk [Blocker].png';

// Libero 
import Isaman from './Team/With BG/Isaman A [Libero].png';
import Manoj from './Team/With BG/Manoj Kumar [Universal].png';

// Setter 
import Janshad from './Team/With BG/Janshad U [Setter].png';
import Saqlain from './Team/With BG/Saqlain Tariq [Setter].png';

// Grid Item Component to display player info
const GridItem = ({ image, name, position, hoverImage, onHover }) => {
  return (
    <div className='relative flex flex-col items-center md:w-1/5 w-full mb-2 gap-4 mx-1'>
      <img 
        src={image} 
        alt={name} 
        className='z-10' 
        onMouseEnter={() => onHover({ name, position, hoverImage })}
        onMouseLeave={() => onHover(null)}
      />
      <span className='font-bold text-xl py-1 z-30'>{name}</span>
      <span className='font-semibold z-30'>{position}</span>
    </div>
  );
};

// Modal Box Component to display player hover image
const ModalBox = ({ player, isVisible, onClose }) => {
  if (!isVisible || !player) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white py-5 px-4 rounded-lg relative mx-auto">
        <button 
          className="absolute top-0 right-1 text-2xl font-bold cursor-pointer" 
          onClick={onClose}
        >
          &times;
        </button>
        
        <img src={player.hoverImage} alt={player.name} className="w-auto md:h-96 h-auto mb-4" />
        <h2 className="text-center font-bold text-xl mb-2">{player.name}</h2>
        <p className="text-center font-semibold">{player.position}</p>
      </div>
    </div>
  );
};

// Main Section for the Toofani Squad
const SectionToofaniSquad = () => {
  const [hoveredPlayer, setHoveredPlayer] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);

  let enterTimeout, leaveTimeout;

  // Handle hover event to show modal with debounce to prevent flickering
  const handleHover = (player) => {
    if (leaveTimeout) clearTimeout(leaveTimeout);

    if (player) {
      enterTimeout = setTimeout(() => {
        setHoveredPlayer(player);
        setModalVisible(true);
        console.log('Player hovered:', player); // Debugging line
      }, 500);  // small delay to smooth out the hover transition
    } else {
      clearTimeout(enterTimeout);  // Prevent hover from triggering after mouse leaves
      leaveTimeout = setTimeout(() => {
        setModalVisible(false);
        console.log('Hover left, closing modal'); // Debugging line
      }, 20000);
    }
  };

  // Arrays of player data
  const attackers = [
    { image: Amal, name: 'Amal K Thomas', position: 'Attacker', hoverImage: AmalHover },
    { image: Santosh, name: 'Santhosh S', position: 'Attacker', hoverImage: SantoshHover },
    { image: Anu, name: 'Anu James', position: 'Attacker', hoverImage: AnuHover },
    { image: Rohit, name: 'Rohit Kumar', position: 'Attacker', hoverImage: RohitHover },
  ];

  const blockers = [
    { image: Daniel, name: 'Daniel Aponza', position: 'Middle Blocker', hoverImage: DanielHover },
    { image: Alan, name: 'Alan Philip Sajan', position: 'Middle Blocker', hoverImage: AlanHover },
    { image: Ayush, name: 'Ayush Kasnia', position: 'Middle Blocker', hoverImage: AyushHover },
    { image: Fayis, name: 'Fayis NK', position: 'Middle Blocker', hoverImage: FayisHover },
  ];

  const liberos = [
    { image: Isaman, name: 'Insamam A', position: 'Libero', hoverImage: IsamanHover },
    { image: Anand, name: 'Anand K', position: 'Libero', hoverImage: AnandHover },
  ];

  const setters = [
    { image: Janshad, name: 'Janshad U', position: 'Setter', hoverImage: JanshadHover },
    { image: Saqlain, name: 'Saqlain Tariq', position: 'Setter', hoverImage: SaqlainHover },
  ];

  const other = [
    { image: Lazar, name: 'Lazar Dodic', position: 'Opposite', hoverImage: LazarHover },
    { image: Manoj, name: 'Manoj Kumar', position: 'Universal', hoverImage: ManojHover },
  ];

  return (
    <section className='mb-6'>
      <h1 className='py-4 bg-[#ffbd00] text-center text-4xl font-bold'>Toofani Squad</h1>
      <div className='max-auto md:px-[8rem]'>

        {/* Attackers Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Attacker</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {attackers.map((player, index) => (
            <GridItem key={index} {...player} onHover={handleHover} />
          ))}
        </div>

        {/* Blockers Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Blocker</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {blockers.map((player, index) => (
            <GridItem key={index} {...player} onHover={handleHover} />
          ))}
        </div>

        {/* Liberos Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Libero</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {liberos.map((player, index) => (
            <GridItem key={index} {...player} onHover={handleHover} />
          ))}
        </div>

        {/* Setters Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Setter</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {setters.map((player, index) => (
            <GridItem key={index} {...player} onHover={handleHover} />
          ))}
        </div>

        {/* Other Position Section */}
        <h2 className='text-center md:text-4xl py-5 font-semibold text-[#3b0067] text-2xl'>Other</h2>
        <div className="flex flex-wrap justify-center gap-5">
          {other.map((player, index) => (
            <GridItem key={index} {...player} onHover={handleHover} />
          ))}
        </div>
      </div>

      {/* Modal for player hover */}
      <ModalBox 
        player={hoveredPlayer}
        isVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
      />
    </section>
  );
};

export default SectionToofaniSquad;
