import React from 'react'
import SlideShow from './SlideShow';
const Hero = () => {
  return (
    <div>
        {/* <Swiper/> */}
        <SlideShow/>
    </div>
  )
}

export default Hero