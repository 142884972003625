import React from 'react'

const NewsUpdated = () => {
    return (
      <div style={{ width: '100%', height: '100vh', paddingTop: '60px' }}> 
        <iframe
          src="https://www.primevolleyballleague.com/news"
          title="News and Updates"
          style={{ width: '100%', height: 'calc(100vh - 60px)', border: 'none' }} 
        ></iframe>
      </div>
    );
  }
  

export default NewsUpdated