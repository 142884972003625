import React, { useState } from 'react';
import image1 from './Squad/Team/Without BG/Danial Aponza [Blocker].png';
import image2 from './Squad/Team/Without BG/Alan Sajan [Blocker].png';
import image3 from './Squad/Team/Without BG/Amal Thomas [Attacker].png';
import image4 from './Squad/Team/Without BG/Isaman A [Libero].png';
import image5 from './Squad/Team/Without BG/Saqlain Tariq [Setter].png';
import image6 from './Squad/Team/Without BG/Santosh S [Attacker].png';
import image7 from './Squad/Team/Without BG/Anand K [Blocker].png';
import image8 from './Squad/Team/Without BG/Anu James [Attacker].png';
import image9 from './Squad/Team/Without BG/Ayush Kasnia [Blocker].png';
import image10 from './Squad/Team/Without BG/Fayis NK [Blocker].png';
import image11 from './Squad/Team/Without BG/Janshad U [Setter].png';
import image12 from './Squad/Team/Without BG/Lazar Dodic [Attacker].png';
import image13 from './Squad/Team/Without BG/Manoj Kumar [Universal].png';
import image14 from './Squad/Team/Without BG/Rohit kumar [Attacker].png';

import selectedImage1 from './Squad/Team/With BG/Danial Aponza [Blocker].png';
import selectedImage2 from './Squad/Team/With BG/Alan Sajan [Blocker].png';
import selectedImage3 from './Squad/Team/With BG/Amal Thomas [Attacker].png';
import selectedImage4 from './Squad/Team/With BG/Isaman A [Libero].png';
import selectedImage5 from './Squad/Team/With BG/Saqlain Tariq [Setter].png';
import selectedImage6 from './Squad/Team/With BG/Santosh S [Attacker].png';
import selectedImage7 from './Squad/Team/With BG/Anand K [Blocker].png';
import selectedImage8 from './Squad/Team/With BG/Anu James [Attacker].png';
import selectedImage9 from './Squad/Team/With BG/Ayush Kasnia [Blocker].png';
import selectedImage10 from './Squad/Team/With BG/Fayis Nk [Blocker].png';
import selectedImage11 from './Squad/Team/With BG/Janshad U [Setter].png';
import selectedImage12 from './Squad/Team/With BG/Lazar Dodic [Attacker].png';
import selectedImage13 from './Squad/Team/With BG/Manoj Kumar [Universal].png';
import selectedImage14 from './Squad/Team/With BG/Rohit kumar [Attacker].png';
import { Link } from 'react-router-dom';

const Squad = () => {
  const players = [
    {
      name: 'Daniel Aponza',
      role: 'Middle Blocker',
      thumbnail: image1,
      image: selectedImage1,
      details: {
        name: 'Daniel Aponza',
        position: 'Middle Blocker',
        description: "Standing tall at 200 cm, Colombian middle blocker Daniel Aponza commands the court with a spike reach of 365 cm and a block reach of 348 cm. Born in Bogotá, he has demonstrated his talent and dedication playing for Calcit Kamnik and Team Lakkapää. Representing Colombia, Daniel's commitment to volleyball earned him the Best Middle Blocker award at the 2023 South American Championship. His early prowess was evident when he was named Best Middle Blocker at the Pan American Cup U21. Daniel's impressive stature and skills make him a formidable force at the net.",
        stats: {
          Height: 200,
          Hname:'Height (cms).',
          SpikeH: 365,
          SHname:'Spike Height (cms).',
          BlockH: 348,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Insamam A',
      role: 'Libero',
      thumbnail: image4,
      image: selectedImage4,
      details: {
        name: 'Insamam A',
        position: 'Libero',
        description: "Insamam's journey began with his stellar stints at the National School Volleyball Championships. He then made a name for himself by winning college and university tournaments and now he continues to be a significant preesence in the Indian volleyball circuit.",
        stats: {
          Height: 184,
          Hname:'Height (cms).',
          SpikeH: 340,
          SHname:'Spike Height (cms).',
          BlockH: 315,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Alan Sajan',
      role: 'Blocker',
      thumbnail: image2,
      image: selectedImage2,
      details: {
        name: 'Alan Sajan',
        position: 'Blocker',
        description: 'Alan Sajan has been making waves in the volleyball setup since his junior days. A standout player from Kerala, he has won several domestic championships for his school and university. With a career rooted in determination and discipline, Alan is a talent to watch',
        stats: {
          Height: 200,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 339,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Amal Thomas',
      role: 'Attacker',
      thumbnail: image3,
      image: selectedImage3,
      details: {
        name: 'Amal Thomas',
        position: 'Attacker',
        description: "Representing the Indian Army Red team, Amal K Thomas has demonstrated unmatched strenght and skill to help his team secure podium finished multiple times in the domestic circuit. A Keralite, Amal's powefull plays make him a vital asset for the Toofans.",
        stats: {
          Height: 179,
          Hname:'Height (cms).',
          SpikeH: 330,
          SHname:'Spike Height (cms).',
          BlockH: 320,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Saqlain Tariq',
      role: 'Setter',
      thumbnail: image5,
      image: selectedImage5,
      details: {
        name: 'Saqlain Tariq',
        position: 'Setter',
        description: "Saqlain Tariq is the captain of the Toofans and also the first spiker to emerge from Jammu and Kashmir. He debited for India at the age of 21 and has since won multiple medals at the domestic, national and international circuit.",
        stats: {
          Height: 190,
          Hname:'Height (cms).',
          SpikeH: 328,
          SHname:'Spike Height (cms).',
          BlockH: 310,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Santhosh S',
      role: 'Attacker',
      thumbnail: image6,
      image: selectedImage6,
      details: {
        name: 'Santhosh S',
        position: 'Attacker',
        description: "Known for his explosive athleticism, Santhosh S has left his mark on national and international games. He has competed at the World University Games in China and was a part of the 2023 Asian Games India squad. Santhosh's versatility and adaptability are signs ofa star player in the making.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 355,
          SHname:'Spike Height (cms).',
          BlockH: 345,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Anand K',
      role: 'Libero',
      thumbnail: image7,
      image: selectedImage7,
      details: {
        name: 'Anand K',
        position: 'Libero',
        description: "Anand is a young player from Kerala who's known for his consecutive podium finishes at the University and National games. His remarkable retrieving ability and aggression help him leave a mark on the court.",
        stats: {
          Height: 174,
          Hname:'Height (cms).',
          SpikeH: 320,
          SHname:'Spike Height (cms).',
          BlockH: 310,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Anu James',
      role: 'Attacker',
      thumbnail: image8,
      image: selectedImage8,
      details: {
        name: 'Anu James',
        position: 'Attacker',
        description: "Anu James is a standout volleyball player from Kerala, India with a strong repulation in the national circuit. With him in their team, Kerala has finished on the podium multiple times from 2014 to 2022 at the Senior Nationals. Known for his strngth and agility, Anu has represented India in the 2014 Asian Junior Championship in Bahrain.",
        stats: {
          Height: 192,
          Hname:'Height (cms).',
          SpikeH: 345,
          SHname:'Spike Height (cms).',
          BlockH: 330,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Ayush Kasnia',
      role: 'Blocker',
      thumbnail: image9,
      image: selectedImage9,
      details: {
        name: 'Ayush Kasnia',
        position: 'Blocker',
        description: "Ayush Kasnia, a renowned name from Haryana, is a consistent performer with multiple national and international gold medals. His exploits at the Youth Asian Championship and AVC Challenger Cup 2023 showcase his potential to become one of India's top volleyball talents.",
        stats: {
          Height: 204,
          Hname:'Height (cms).',
          SpikeH: 360,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Fayis NK',
      role: 'Blocker',
      thumbnail: image10,
      image: selectedImage10,
      details: {
        name: 'Fayis NK',
        position: 'Blocker',
        description: "Fayis NK, a powerful presence on the court, has consistently helped Kerala secure podium finishes in national tournaments. His experience and steady ascent in the sport are a hallmark of a great talent.",
        stats: {
          Height: 194,
          Hname:'Height (cms).',
          SpikeH: 340,
          SHname:'Spike Height (cms).',
          BlockH: 328,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Janshad U',
      role: 'Setter',
      thumbnail: image11,
      image: selectedImage11,
      details: {
        name: 'Janshad U',
        position: 'Setter',
        description: "Janshad U represents Kerala in multiple domestic tournaments. He has also helped them win the National and Federation Cup in 2013, making him one of the most respected players in the volleyball circuit.",
        stats: {
          Height: 187,
          Hname:'Height (cms).',
          SpikeH: 328,
          SHname:'Spike Height (cms).',
          BlockH: 315,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Lazar Dodic',
      role: 'Attacker',
      thumbnail: image12,
      image: selectedImage12,
      details: {
        name: 'Lazar Dodic',
        position: 'Attacker',
        description: "Serbian player Lazar Dodic has been a consistent and top performer on the international stage. He was recognized as the best scorer in the Serbian Cup and Super League on 2018-2019.",
        stats: {
          Height: 201,
          Hname:'Height (cms).',
          SpikeH: 352,
          SHname:'Spike Height (cms).',
          BlockH: 335,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Manoj Kumar',
      role: 'Universal',
      thumbnail: image13,
      image: selectedImage13,
      details: {
        name: 'Manoj Kumar',
        position: 'Universal',
        description: "A natural leader, Manoj Kumar is a dynamic volleyball player from Haryana, India. Under his leadership, Haryana won the gold multiple times in domestic circuit, while he earned the title Best Player award at the 36th Federation Cup. His commitment and skills make him an inspiring figure in Indian volleyball.",
        stats: {
          Height: 200,
          Hname:'Height (cms).',
          SpikeH: 352,
          SHname:'Spike Height (cms).',
          BlockH: 335,
          BHname:'Block Height (cms).'
        },
      },
    },
    {
      name: 'Rohit Kumar',
      role: 'Attacker',
      thumbnail: image14,
      image: selectedImage14,
      details: {
        name: 'Rohit Kumar',
        position: 'Attacker',
        description: "Rohit Kumar is an established name in the Indian volleyball circuit with an impressive domestic and international track record. He holds a World Railway Gold and has represented India in the 2018 and 2022 Asian Games. Rohit's powerful spikes and sharp instincts are just terrific.",
        stats: {
          Height: 195,
          Hname:'Height (cms).',
          SpikeH: 358,
          SHname:'Spike Height (cms).',
          BlockH: 340,
          BHname:'Block Height (cms).'
        },
      },
    },
    // Add more players as needed
  ];

  const [selectedPlayer, setSelectedPlayer] = useState(players[0]);

  const handlePlayerClick = (player) => {
    setSelectedPlayer(player);
  };

  return (
    <section className='bg-[#3b0067] md:pt-6 justify-center items-center mx-auto m-2 md:my-10 md:px-[9rem]'>
      <h1 className='md:text-5xl text-3xl text-white text-center font-bold mb-7'>Squad</h1>
      <div className='flex flex-col lg:flex-row gap-5 h-auto'>
      <div className='flex flex-col w-full justify-center lg:w-1/3'>
          <div className='h-96 overflow-y-auto'>
            {players.map((player, index) => (
              <div 
                key={index} 
                className='flex items-center p-2 rounded-md cursor-pointer'
                onClick={() => handlePlayerClick(player)}
              >
                <img src={player.thumbnail} alt={player.name} className='object-cover w-20 h-20 object-fill shadow-2xl rounded-md' />
                <div className='ml-4 text-white'>
                  <h4 className='text-lg font-bold'>{player.name}</h4>
                  <span className='text-sm'>{player.role}</span>
                </div>
              </div>
            ))}
          </div>
          <Link to={'/Squad'}>
          <button className='bg-[#ffbd00] px-5 py-1.5 w-24 text-sm rounded-md text-black font-medium mt-3'>View All</button>
          </Link>
        </div>

        <div className='w-full lg:w-1/3 h-auto'>
          <img src={selectedPlayer.image} alt="News" className='rounded-md' />
        </div>

        <div className='w-full lg:w-1/3 pl-4 pt-10'>
          <h2 className='text-4xl font-bold mb-3 text-[#ffbd00]'>{selectedPlayer.details.name}</h2>
          <h5 className='text-xl text-white mb-4 font-semibold'>{selectedPlayer.details.position}</h5>
          <p className='text-sm text-white mb-4'>{selectedPlayer.details.description}</p>
          <div className='grid grid-cols-4 gap-2 text-white'>
            <div className='text-left '>
              <span className='text-2xl font-extrabold block'>{selectedPlayer.details.stats.TotalP || selectedPlayer.details.stats.Height}</span>
              <span className='text-m font-bold'>{selectedPlayer.details.stats.TPName || selectedPlayer.details.stats.Hname }</span>
            </div>
            <div className='text-left'>
              <span className='text-2xl font-extrabold block'>{selectedPlayer.details.stats.AttackP || selectedPlayer.details.stats.SpikeH}</span>
              <span className='text-m font-bold '>{selectedPlayer.details.stats.APName || selectedPlayer.details.stats.SHname }</span>
            </div>
            <div className='text-left'>
              <span className='text-2xl font-extrabold block'>{selectedPlayer.details.stats.BlockP || selectedPlayer.details.stats.BlockH}</span>
              <span className='text-m font-bold  '>{selectedPlayer.details.stats.BPName || selectedPlayer.details.stats.BHname }</span>
            </div>
            <div className='text-left'>
              <span className='text-2xl font-extrabold block'>{selectedPlayer.details.stats.ServeP}</span>
              <span className='text-m font-bold '>{selectedPlayer.details.stats.SPName}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Squad;
