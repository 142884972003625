import React from 'react'

import prism from '../Sponsors/Sponsors/Prism Cement logo.png'
import johnson from '../Sponsors/Sponsors/Johnson Logo.png'

import gaana from '../Sponsors/Sponsors/Gaana logo.png'
import mirchi from '../Sponsors/Sponsors/Mirchi logo.png'
import exicom from '../Sponsors/Sponsors/exicom logo.png'

import dafa from '../Sponsors/Sponsors/Dafa News logo.png'
import gordSpot from '../Sponsors/Sponsors/Gold Spot logo.png'
import alcis from '../Sponsors/Sponsors/Alcis logo.png'

const SectionSponsors = () => {
  return (
   <section className='md:mx-10 mt-10 mx-1'>
    <div className='bg-white justify-center items-center rounded-t-2xl shadow-[#3b0067] shadow-inner'>
        <h2 className='text-center text-[#3b0067] text-4xl p-3 my-2 font-semibold'>Principal Sponsors</h2>
        <div className='md:flex grid grid-flow-row items-center justify-center gap-4 py-4'>
            <a href='https://www.prismcement.com/' target='blank'>  
                <img src={prism} alt="" /> 
            </a>
            <a href='https://www.hrjohnsonindia.com/' target='blank'>
                 <img src={johnson} alt="" />
            </a>
        </div>
        <h2 className='text-center text-[#3b0067] text-4xl p-3 font-semibold'>Associate Sponsors</h2>
        <div className='md:flex grid grid-flow-row  items-center justify-center gap-4 py-4'>
           <a href='https://gaana.com/' target='blank'>
                <img src={gaana} alt="" />
            </a>
            <a href='https://mirchi.in/stories' target='blank'>
                <img src={mirchi} alt="" /> 
            </a>
            <a href='https://www.exicom.in/' target='blank'>
            <img src={exicom} alt="" />
           </a>
        </div>
        <div className='md:flex grid grid-flow-row items-center justify-center gap-5 py-6'>
            <a href='https://in.dafanews.com/' target='blank'>
                <img src={dafa} alt="" />
            </a>
                <img src={gordSpot} alt="" />
            <a href='https://www.alcissports.com/' target='blank'>
                <img src={alcis} alt="" />
            </a>
        </div>
    </div>
   </section>
  )
}

export default SectionSponsors